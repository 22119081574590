
@font-face {
    font-family: 'PQMSPORT';
    src: url('../../Fonts/Avenir.ttc') format('truetype');
    font-weight: normal;
  }
  * {
    margin: 0;
    padding: 0;

  }

  .imagen_general_escuelas{
    background: url(../../images/fondo_parcial.png)center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .container_escuela{
    padding-top: 7%;
  }
  .contenedor_home_escuela{
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 20px 20px;

  }
  .flex-derecha{
      position: absolute;
  }
    .contenedor_home_escuela{
        flex-direction: row;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .title_ecuelas{
        font-family: 'PQMSPORT';
        color: rgba(255, 255, 255, 0.9);
        font-size: 30px;
        font-weight: bold;
    }
    
    /*NAVIGATION*/
    .dropdown-menu{
        background-color: rgba(246,190,0);
    }

/*Card */
.etiqueta{
    color: rgba(246,190,0,0.9);
    position: absolute;
    font-size: 20px;
    background-color: #000;
    padding: 10px;
    right: 0;
    bottom: 0;
    border-top-left-radius: 25px;
    font-family: 'PQMSPORT';
    font-weight: bold;
}
.box{
    width: 300px;
    height: 400px;
    position: relative;
    overflow: hidden;
    margin: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
.box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.capa{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#fff;
    transform: scale(0);
    transition: .3s ease;
}
.capa h2{
    font-size: 20px;
    margin-bottom: 10%;
}
.box:hover .capa{
    transform: scale(1);
}
.box:hover .etiqueta{
    color:transparent;
    position: absolute;
    font-size: 20px;
    background-color: transparent;
    padding: 10px;
    right: 0;
    bottom: 0;
    border-top-left-radius: 25px;
    font-family: 'PQMSPORT';
    font-weight: bold;
}
.nav-link{
    color: #fff;
}
.nav-link h6:hover{
    color: rgba(246,190,0,0.9);
}
.iconos_chiquitos{
    width: 100px;
}

/* Medias Query 768 ---------------------------------------------*/
@media (max-width: 768px) {
    .imagen_general_escuelas{
        background: url(../../images/fondo_parcial.png)center no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        height: 120vh;
      }
      .nav-link{
        font-size: 10px;
      }
      .container_escuela{
        padding-top: 30%;
      }
      .contenedor_home_escuela{
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 0px 0px 20px 20px;
    
      }
      .flex-derecha{
          position: absolute;
      }
        .contenedor_home_escuela{
            flex-direction: row;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
        .title_ecuelas{
            font-family: 'PQMSPORT';
            color: rgba(255, 255, 255, 0.9);
            font-size: 20px;
            font-weight: bold;
        }
        
        /*NAVIGATION*/
        .dropdown-menu{
            background-color: rgba(246,190,0);
        }
    
    /*Card */
    
    .box{
        width: 300px;
        height: 400px;
        position: relative;
        overflow: hidden;
        margin: 20px;
        border-radius: 20px;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    }
    .box img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .capa{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color:#fff;
        transform: scale(0);
        transition: .3s ease;
    }
    .capa h2{
        font-size: 20px;
        margin-bottom: 10%;
    }
    .box:hover .capa{
        transform: scale(1);
    }
    .nav-link{
        color: #fff;
    }
    .nav-link h6:hover{
        color: rgba(246,190,0,0.9);
    }
    .iconos_chiquitos{
        width: 100px;
    }

}


/*FORMATO DE LAS ESCUELAS*/
.contenedor_text_p {
    background-color: rgba(0, 0, 0, 0.455);
    padding: 1% 5% 5% 5%;
    border-radius: 25px;
}
.contenedor_text1_escuela{
    background-color: yellowgreen;
    border-radius: 25px;
    padding: 25px;
}
.titulo_al_esc_un{
    color: rgba(246,190,0,0.9);
    font-family: 'PQMSPORT';
    font-size: 25px;
    font-weight: bold;

}
.titulo_esc_un{
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
}
.titulo_esc_un_t{
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    text-align: center;
}
.barner_esc_un{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgb(246, 189, 0));

}
.container_esc_un{
    flex-direction: column;
    display: flex;
    background: url(../../images/fondo_parcial.png)center no-repeat;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.contenedor_escuela1x{
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 100px;
}
.izquierda_xd{
    margin-left: 10px;
    padding-left: 50px;
    padding-right: 50px;
}
.izquierda_separado{
    display: flex;
    flex-direction: row;
}
.titlex_esc_un{
    color: #fff;
    font-family: 'PQMSPORT';
    font-size: 17px;
    font-weight: bold;
    margin-top: 10px;
}
.input_esc_un{
    color: rgba(232, 232, 232, 0.9);
    font-family: 'PQMSPORT';
    font-size: 14px;
    margin: 10px;
    font-weight: bold;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
}
.checkbos_x{
    color: rgba(232, 232, 232, 0.9);
    font-family: 'PQMSPORT';
    font-size: 14px;
    margin: 10px;
    font-weight: bold;
    margin-top: 10px;


}

/* Medias Query 768 ---------------------------------------------*/
@media (max-width: 768px) {
    .contenedor_escuela1x{
        display: flex;
        flex-direction: column;
        margin: 5%;
        margin-top: 30%;

    }
    .izquierda_xd{
        margin-left: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .input_esc_un{
        color: rgba(232, 232, 232, 0.9);
        font-family: 'PQMSPORT';
        font-size: 14px;
        margin:2px;
        font-weight: bold;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-items:normal;
        justify-content:start;
    }
    
.titulo_esc_un{
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
}
.titulo_esc_un_t{
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    text-align:center;
}
}