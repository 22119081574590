@font-face {
  font-family: 'PQMSPORT';
  src: url('../../Fonts/Avenir.ttc') format('truetype');
  font-weight: normal;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 25px;
  color: #7a7a7a;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: 'Poppins', sans-serif;
}

::selection {
  background: #ff8800;
  color: #fff;
}

::-moz-selection {
  background: #ff8800;
  color: #fff;
}

@media (max-width: 991px) {
  html, body {
    overflow-x: hidden;
  }
  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .mobile-bottom-fix {
    margin-bottom: 30px;
  }
  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.section-heading {
  text-align: center;
  margin-top: 140px;
  margin-bottom: 80px;
}

.section-heading h2 {
  font-size: 28px;
  font-weight: 800;
  color: #232d39;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-heading h2 em {
  font-style: normal;
  color: #ff8800;
}

.section-heading img {
  margin: 20px auto;
}

.dark-bg h2 {
  color: #fff;
}

.dark-bg p {
  color: #fff;
}

.main-button a {
  display: inline-block;
  font-size: 15px;
  padding: 12px 20px;
  background-color: #ff8800;
  color: #fff;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  transition: all .3s;
}

.main-button a:hover {
  background-color: #ff8800;
}


/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
  background: rgba(250,250,250,0.99) !important;
  height: 80px!important;
  position: fixed!important;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15)!important;
}

.background-header .logo,
.background-header .main-nav .nav li a {
  color: #232d39!important;
}

.background-header .main-nav .nav li:last-child a {
  color: #fff !important;
}

.background-header .main-nav .nav li:last-child a:hover {
  color: #fff!important;
}

.background-header .main-nav .nav li:hover a {
  color: #ff8800!important;
}

.background-header .nav li a.active {
  color: #ff8800!important;
}

.header-area {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 80px;
  background: rgba(250,250,250,0.1);
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  line-height: 80px;
  color: #fff;
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo em {
  font-style: normal;
  color: #ffa844;
  font-weight: 900;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 27px;
  margin-right: 0px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: #7a7a7a;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  line-height: 40px;
  border: transparent;
  letter-spacing: 1px;
}

.header-area .main-nav .nav li a {
  color: #fff;
}

.header-area .main-nav .nav li:last-child a {
  display: inline-block;
  font-size: 13px;
  padding: 11px 17px;
  background-color: #ffa844;
  color: #fff;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: uppercase;
  transition: all .3s;
  height: auto;
  line-height: 20px;
}

.header-area .main-nav .nav li:last-child a:hover {
  background-color: #ffb764;
  opacity: 1;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #ffa844!important;
  opacity: 1;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #ffa844!important;
  opacity: 1;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #1e1e1e;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area.header-sticky .nav {
  margin-top: 20px !important;
}

.header-area.header-sticky .nav li a.active {
  color: #ff8800;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }
  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-area .main-nav .logo {
    color: #1e1e1e;
  }
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #ff8800!important;
    opacity: 1;
  }
  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }
  .header-area .container {
    padding: 0px;
  }
  .header-area .logo {
    margin-left: 30px;
  }
  .header-area .menu-trigger {
    display: block !important;
  }
  .header-area .main-nav {
    overflow: hidden;
  }
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }
  .header-area .main-nav .nav li:last-child {
    width: 100%;
    background-color: #ffa844;
    color: #fff;
  }
  .header-area .main-nav .nav li:last-child a {
    background-color: #ffa844!important;
  }
  .header-area .main-nav .nav li:last-child a:hover,
  .header-area .main-nav .nav li:last-child:hover a {
    background-color: #ffa844!important;
    color: #fff!important;
  }
  .header-area.header-sticky .nav {
    margin-top: 80px !important;
  }
  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #232d39 !important;
  }
  .header-area .main-nav .nav li:last-child a {
    color: #fff!important;
  }
  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #ffa844!important;
  }
  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }
  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }
  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }
  .header-area .main-nav .nav li.submenu:after {
    color: #3B566E;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }
  .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}


/* 
---------------------------------------------
banner
--------------------------------------------- 
*/

.main-banner {
  position: relative;
}

#bg-video {
    min-width: 100%;
    min-height: 100vh;
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
    z-index: -1;
}

#bg-video::-webkit-media-controls {
    display: none !important;
}

.video-overlay {
    position: absolute;
    background-color: rgba(35,45,57,0.8);
    top: 0;
    left: 0;
    bottom: 7px;
    width: 100%;
}

.main-banner .caption {
  text-align: center;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.main-banner .caption h6 {
  margin-top: 0px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 0.5px;
}

.main-banner .caption h2 {
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 90px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  letter-spacing: 1px;
}

.main-banner .caption h2 em {
  font-style: normal;
  color: #ff8800;
  font-weight: 900;
}
.main-banner .caption p {
  font-style: normal;
  color: #ff8800;
  font-weight: 900;
  font-size: 20px;
}
/*Boton whatsapp*/

.container-boton{
  background-color: #2e6329;
  border: 1px solid #fff;
  position: fixed;
  z-index: 999;
  border-radius: 50%;
  bottom: 20px;
  right: 25px;
  padding: 20px;
  transition: ease 0.3s;
  animation: efecto 1.2s infinite;
}

.container-boton:hover{
  transform: scale(1.1);
  transition: 0.3s;
}

.boton{
  width: 50px;
  transition: ease 1s;
}
@keyframes efecto{
  0%{
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.85);
  }
  100%{
      box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
}
/* 
---------------------------------------------
Abouts
--------------------------------------------- 
*/

.informacion_home{
  padding-top: 10%;
  text-align: center;
  height: 400px;
}
.informacion_home h2{
  color: #090909;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  padding-bottom: 30px;
}
.informacion_home em{
  color: #ff8800;
  font-style: normal;
}
.informacion_home p{
  color: #373737;
  font-size: 18px;
  margin-right: 15%;
  margin-left: 15%;
}
/* 
---------------------------------------------
portafolio
--------------------------------------------- 
*/
.portafolio{
  display:grid;
  grid-template-columns: 30% 40% 30%;
  justify-content: center;
}
.portafolio__box{
  background-color:#ffffff;
  border-color:'#000';
  border-width: 2px;
  margin: 5px;

}
.title_porta{
  text-align: center;
  color: #090909;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 1px;
  padding-bottom: 30px;
  padding-top: 10px;
  height: 70px;
}
.text_porta{
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  text-align: justify;
}
.detalles_porta{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: center;
  justify-items: center;
}
.summary_em{
  color: #ff8800;
  padding: 5px;
  font-style: normal;
}



/* 
---------------------------------------------
ESTADISTICAS
--------------------------------------------- 
*/
.estadisticas{
  height: 100%;
  padding: 5%;

}

.img-fluid{
  width: 100px;
  height: 100px;
}
.texto_estadisticas{
  text-align: center;

}
.textoxd{
  color: #090909;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  padding-bottom: 30px;
}
.texto_estadisticas h5{
  color: #ff9d34;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  font-style: normal;
  padding-bottom: 30px;
}
.contenedor_estadisticas_numeros{
  padding: 20px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  text-align: center;
  margin-bottom: 50px;
}
.contenedor_estadisticas_numeros h6{
  font-size: 30px;
}
.skillBanner h6{
  color: #000000;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  padding-bottom: 30px;
}
.margin_x{
  margin-left: 30px;
}
.skillBanner h6 em{
  color: #ff9d34;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  padding-bottom: 30px;
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
}

/* 
---------------------------------------------
Contacto
--------------------------------------------- 
*/

.contacto_section{
  height: 100vh;
  display: grid;
  grid-template-columns: 60% 40%;
  margin-top: 5%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.contactotitulo{
  color: #ff9d34;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
}
.imagen_contacto_portada{
  width: 100%;
  
}

.contacto_derecha{
  background-color: rgb(255, 255, 255);
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  padding: 40px;
}

.iconos_contacto {
  color: #fff;
  font-size: 15px;
  text-align: center;
  font-weight: 800;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contacto_izquierda{
  height: 100%;
  border-radius: 10px;

}
.footer__ulx{
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
}
.footer__ulx a {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-self: center;
  text-align: center;
  margin: 0.5rem 0.8rem;
  font-size: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  color: white;
}
/* 
---------------------------------------------
Footer 
--------------------------------------------- 
*/
.footer{
  background-color: #232d39;
  width: 100%;
  justify-content: center;
  color: #fff;
}
.filas{
  display: grid;
  grid-template-columns: 75% 25%;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 30vh;
}
.boton_contacto a{
  text-decoration: none;
  color: rgba(246,190,0,0.9);
  border-radius: 15px;
  font-weight: bold;
  padding: 10px;
  border: 2px solid rgba(246,190,0,0.9); /* Green */
}
.boton_contacto a:hover{
  text-decoration: none;
  color: #ffffff;
  background-color: rgba(246,190,0,0.9);
  font-weight: bold;

}

.footer__ul{
  display: grid;
  grid-template-columns: 50% 50%;
  height: 60vh;
  justify-items: center;
  justify-content: center;
  align-items: center;
}
.portafolio__box-url{
  color: white;
  text-decoration: none;
}
.portafolio__box-url:hover{
  color: rgba(246,190,0,0.9);
  text-decoration: none;
}
.footer_xd{
  display:flex;
  justify-content: center;
  flex-direction: column;
}
.iconx{
  width: 200px;
  border-radius: 50px;
}
.commonBorder{
  width: 100%;
  background-color: #eee;
  height: 1px;
}
.parteizquierdafooter{
  padding: 20px;
}
.parteizquierdafooter h6{ 
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  font-style: normal;
  padding-top: 30px;

}
.centrado_footer{
  text-align: center;
}
/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/


@media (max-width: 992px) {

  .main-banner .caption h2 {
    font-size: 64px;
  }
  #features {
    margin-bottom: 110px;
  }
  #features .feature-item {
    margin-bottom: 30px;
  }
  #our-classes .tabs-content {
    margin-left: 0px;
    margin-top: 30px;
  }
  .trainer-item {
    margin-bottom: 30px;
  }
  #contact-us #map {
    margin-bottom: -7px;
  }
  #contact-us .contact-form {
    padding: 30px;
  }
  #contact-us .contact-form #contact {
    padding: 30px;
  }

}

@media (max-width: 450px) {
  .feature-item .right-content a.text-button {
    margin-left: 130px;
  }
}