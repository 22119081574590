.contenedor_principal_presentacion{
    background-image: url('../../images/fondo.jpg');
    background-repeat: no-repeat;
    background-size: cover;

}
.container_representacion{
    width: 100%;
    height: 100vh;
    background-color: #232d39d3;
    padding-top: 5%;
}

.icono_representacion{
    width:400px;
    position: relative;
    top:-80px;
    border-radius: 50%;
}
.representacion_textox{
    padding: 5%;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-self: center;
    align-self: center;

}
.portada_representacion{
    padding: 7%;
    display: grid;
    grid-template-columns: 25% 75%;

}
.portada_representacion h1{
    color: #fff;
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.portada_representacion h2{
    color: rgb(251, 147, 0);
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
    text-align: justify;
    margin-bottom: 10px;
}
.portada_representacion p{
    color: rgb(182, 182, 182);
    font-size: 15px;
    font-weight: 800;
    text-align: justify;
}
.representacion_section2{
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 10%;
}


.representacion_section2 h2{
    color: rgb(251, 147, 0);
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
    padding: 10px;

}

.container_repre_nosotros{
    background-color: black;
    color: #fff;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    margin: 5%;
}
.container_repre_nosotros p{
    color: #fff;
    padding: 10px;
}


.filas_imagenes{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: center;
}

.Imagen_representacion{
    width: 100%;
    border-radius: 25px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.contenedor_repre_servicios{
    height: 100vh;
    margin-top: 10%;
}
.contenedor_repre_servicios_cuadros{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
}
.cuadro_repre{
    -webkit-box-shadow: 1px 18px 24px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 18px 24px 1px rgba(0,0,0,0.75);
    box-shadow: 1px 18px 24px 1px rgba(0,0,0,0.75);
    border-radius: 25px;
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-image: url('../../images/fondo_equipo.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.cuadro_repre:hover{

    background-color: yellowgreen;
}
.iconos-servicios{
    font-size: 50px;
    color: #fff;
}

.contenedor_repre_servicios h2{
    color: rgb(251, 147, 0);
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 1px;
    margin-bottom: 10px;
}
.card-text{
    color: rgb(228, 228, 228);
}
