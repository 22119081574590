
/*Boton de registros*/
.registros{
   color: salmon;
}

.navegador_admin{
   float: left;
   background-color: rgb(246,190, 0);
   border-bottom-right-radius: 300px;
   height: 400px;

 }
.navegador_admin ul{
    list-style-type: none;
    display: flex;
    padding-bottom:100%;
    padding-right: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration:none ;
 }
 .container_admin_seconds{
   flex-direction: column;
   display: flex;

 }
 .icono_logo_admin{
    margin-top: 10px;
    width: 100px;

 }
 .boton_panel{
    margin-top: 10px;
    padding: 5px;
    margin-left: 5px;
    border-width: 0;
    border-radius: 100px;
    background-color:rgb(159, 122, 0);
    color: aliceblue;
    font-weight: bold;
    font-size: 14px;
 }
 .boton_panel:hover{
   margin-top: 10px;
   padding: 5px;
   margin-left: 5px;
   border-width: 0;
   border-radius: 50px;
   background-color:rgba(255, 149, 0, 0.392);
   color: aliceblue;
   font-weight: bold;
   text-decoration:none ;
   font-size: 14px;

}
/*Menu de eventos*/

.contenedor_eventos{
   overflow: scroll;
   height: 250px;
   width: 100%;
}


/*Formulario para ingresar nuevo evento*/
.span_from{
   position: absolute;
   margin-left: 5px;
   height: 25px;
   display: flex;
   align-items: center;
 }
 .input_form{
   padding-left: 25px;
   height: 20px;
 }

 /*PAGINACION DEL ADMIN*/

 .derecha{
   margin-top: 10px;
   text-align: center;
 }
 .row_panel{
   flex-direction: row;
   display: flex;
   justify-items: center;
   align-items: center;
   justify-content:space-between;
   border-top-right-radius: 25px;
   border-top-left-radius: 25px;
 }
 .container_herramienta{
    display: flex;
    flex-direction: row;
    justify-items: center;
   align-items: center;
   justify-content: center;
   text-align: center;
   width: 50%;
 }

 .pagination{
   justify-items: center;
   align-items: center;
   justify-content: center;
   align-content: center;
   margin-bottom: 0px;
 }
/* Medias Query 768 ---------------------------------------------*/
@media (max-width: 768px) {

/*Boton de registros*/
.registros{
  color: salmon;
}
.estadistica_grafica{
  width: 100%;
}
.navegador_admin{
  float: left;
  background-color: rgb(246,190, 0);
  border-bottom-right-radius: 300px;
  height: 400px;
  display: none;

}
.navegador_admin ul{
   list-style-type: none;
   display: flex;
   padding-bottom:100%;
   padding-right: 5px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   text-decoration:none ;
   
}
.container_admin_seconds{
  flex-direction: column;
  display: flex;

}

.icono_logo_admin{
   margin-top: 10px;
   width: 100px;

}
.boton_panel{
   margin-top: 10px;
   padding: 5px;
   margin-left: 5px;
   border-width: 0;
   border-radius: 100px;
   background-color:rgb(159, 122, 0);
   color: aliceblue;
   font-weight: bold;
   font-size: 14px;
}
.boton_panel:hover{
  margin-top: 10px;
  padding: 5px;
  margin-left: 5px;
  border-width: 0;
  border-radius: 50px;
  background-color:rgba(255, 149, 0, 0.392);
  color: aliceblue;
  font-weight: bold;
  text-decoration:none ;
  font-size: 14px;

}
/*Menu de eventos*/

.contenedor_eventos{
  overflow: scroll;
  height: 250px;
  width: 100%;
}


/*Formulario para ingresar nuevo evento*/
.span_from{
  position:relative;
  margin-left: 5px;
  height: 25px;
  display: flex;
  align-items: center;
}
.input_form{
  padding-left: 25px;
  height: 20px;
}

/*PAGINACION DEL ADMIN*/

.derecha{
  margin-top: 10px;
  text-align: center;
  
}
.row_panel{
  flex-direction: column;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content:space-between;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}
.container_herramienta{
   display: flex;
   flex-direction: column;
   justify-items: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
}

.pagination{
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 0px;
}
}