


@media (max-width: 600px) {
/* 
---------------------------------------------
portafolio
--------------------------------------------- 
*/
.portafolio{
    display:grid;
    grid-template-columns: 100%;
    justify-content: center;
  }
  .portafolio__box{
    background-color:#ffffff;
    -webkit-box-shadow: 0px 10px 16px 5px rgba(0,0,0,0.59);
    -moz-box-shadow: 0px 10px 16px 5px rgba(0,0,0,0.59);
    box-shadow: 0px 10px 16px 5px rgba(0,0,0,0.59);
    margin: 10px;
  
  }
  .title_porta{
    text-align: center;
    color: #090909;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 1px;
    padding-bottom: 30px;
    padding-top: 10px;
    height: 30px;
  }
/* 
---------------------------------------------
estadisticas
--------------------------------------------- 
*/
  .contenedor_estadisticas_numeros h6{
    font-size: 16px;
  }
  .contenedor_estadisticas_numeros p{
    font-size: 10px;
    margin: 0;
    text-align: center;
  }
  .contenedor_estadisticas_numeros{
    padding: 10px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    text-align: center;
    margin-bottom: 50px;
  }
/* 
---------------------------------------------
Contacto
--------------------------------------------- 
*/

.contacto_section{
    height: 100%;
    display: grid;
    grid-template-columns: 0 100%;
    margin-top: 5%;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }
  .contacto_derecha{
    background-color: rgba(0, 0, 0, 0.454);
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    text-align: center;
    padding: 40px;
  }
  
/* 
---------------------------------------------
Footer 
--------------------------------------------- 
*/
.footer{
    background-color: #232d39;
    width: 100%;
    justify-content: center;
    color: #fff;
  }
  .filas{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 30vh;
  }
  .boton_contacto a{
    text-decoration: none;
    color: rgba(246,190,0,0.9);
    border-radius: 15px;
    font-weight: bold;
    padding: 10px;
    border: 2px solid rgba(246,190,0,0.9); /* Green */
  }
  .boton_contacto a:hover{
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(246,190,0,0.9);
    font-weight: bold;
  
  }
  
  .footer__ul{
    display: grid;
    grid-template-columns: 50% 50%;
    height: 60vh;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
  .portafolio__box-url{
    color: white;
    text-decoration: none;
  }
  .portafolio__box-url:hover{
    color: rgba(246,190,0,0.9);
    text-decoration: none;
  }
  .footer_xd{
    display:flex;
    justify-content: center;
    flex-direction: column;
  }
  .iconx{
    width: 100px;
  }
  .commonBorder{
    width: 100%;
    background-color: #eee;
    height: 1px;
  }
  .parteizquierdafooter{
    padding: 20px;
  }
  .parteizquierdafooter h6{ 
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    font-style: normal;
    padding-top: 30px;
  
  }
  .centrado_footer{
    text-align: center;
  }
}
    